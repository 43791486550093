
import { defineComponent, onUnmounted, onMounted } from "vue";

const CModal = defineComponent({
  name: "CModal",
  props: {
    show: { type: Boolean, default: true },
  },
  setup(props) {
    onMounted(() => {
      const page = document.querySelector("html");
      if (props.show && page?.style) page.style.overflowY = "hidden";
    });
    onUnmounted(() => {
      const page = document.querySelector("html");
      page?.style.removeProperty("overflow-y");
    });
  },
});

export default CModal;
